<template>
  <div class="register-steps-item-progress tw-relative">
    <svg
      height="96"
      width="96"
      class="tw-absolute tw-left-0 tw-top-0"
    >
      <circle
        cx="48"
        cy="48"
        r="45"
        stroke="#e9e9e9"
        stroke-width="6"
        fill="white"
      />
    </svg>
    <svg
      :style="{
        'stroke-dashoffset': computedOffset
      }"
      height="96"
      width="96"
      class="register-steps-item-progress__circle"
      data-test="circle"
    >
      <defs>
        <linearGradient
          id="gradient"
          x1="50%"
          y1="100%"
          x2="0%"
          y2="100%"
        >
          <stop
            offset="0%"
            stop-color="#c7e15f"
          />
          <stop
            offset="100%"
            stop-color="#96bf31"
          />
        </linearGradient>
      </defs>

      <circle
        cx="48"
        cy="48"
        r="45"
        stroke="url(#gradient)"
        stroke-width="6"
        fill="transparent"
      />
    </svg>
    <div
      class="register-steps-item-progress__content tw-w-full tw-h-full tw-flex tw-absolute tw-top-0 tw-left-0"
      data-test="content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
  import { computed } from '@vue/composition-api'

  /**
   * @module component - RegisterStepsItemProgress
   */
  export default {
    name: 'RegisterStepsItemProgress',
    props: {
      value: {
        type: Number,
        required: true
      }
    },
    setup (props) {
      /**
       * @function computedOffset
       * @returns {ComputedRef<number>}
       */
      const computedOffset = computed(() => {
        const size = 45
        const dashArray = (Math.PI * 2) * size
        // @ts-ignore
        const value = Math.max(0, Math.min(props.value, 100))

        // @ts-ignore
        return Math.round(dashArray - ((value * dashArray) / 100))
      })

      return {
        computedOffset
      }
    }
  }
</script>

<style lang="scss" scoped>

  .register-steps-item-progress {
    $size: 45px;
    $dash-array: (2 * 3.14) * $size;

    &__circle {
      transform: rotate(-90deg);
      stroke-dasharray: $dash-array;
      stroke-dashoffset: $dash-array;
      transition: stroke-dashoffset 200ms ease-in-out;
    }
  }

</style>
