<template>
  <div
    class="register-account-credentials tw-flex tw-flex-col tw-flex-1"
  >
    <div class="register-account-credentials__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0">
      <div
        class="register-account-credentials__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"
      >
        <register-header
          :title="$t('app.titles.register.account')"
          :steps="steps"
          class="tw-mb-10"
          data-test="header"
        />

        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            :disabled="$wait.is('creating account')"
            @submit.prevent="submitted"
          >
            <ValidationProvider
              ref="email-provider"
              :name="$t('app.fields.email')"
              rules="required|email"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="email"
                  v-model="formData.email"
                  :label="$t('app.labels.email')"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :data-vv-as="$t('app.fields.email')"
                  :disabled="$wait.is('creating account')"
                  type="email"
                  name="email"
                  autocomplete="email"
                  class="tw-mb-4"
                  required
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="password-provider"
              vid="password"
              :name="$t('app.fields.password')"
              rules="required|min:6"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  v-model="formData.password"
                  id="password"
                  name="password"
                  type="password"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :minlength="6"
                  :label="$t('app.labels.password') | capitalize"
                  :disabled="$wait.is('creating account')"
                  class="tw-w-full tw-mb-4"
                  autocomplete="new-password"
                  data-test="password"
                  required
                />
              </template>
            </ValidationProvider>

            <ValidationProvider
              ref="password-confirmation-provider"
              :name="$t('app.fields.password')"
              rules="required|min:6|confirmed:password"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  v-model="formData.passwordConfirmation"
                  id="password-confirmation"
                  name="password-confirmation"
                  type="password"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :minlength="6"
                  :label="$t('app.labels.password_confirmation') | capitalize"
                  :disabled="$wait.is('creating account')"
                  class="tw-w-full tw-mb-8"
                  autocomplete="new-password"
                  data-test="password-confirmation"
                  required
                />
              </template>
            </ValidationProvider>

            <ValidationProvider
              ref="tos-provider"
              name="tos"
              rules="required|length:4"
              data-test="tos-provider"
            >
              <template slot-scope="{ invalid, validated }">
                <b-form-checkbox
                  v-model="formData.tos"
                  :disabled="$wait.is('creating account')"
                  :state="invalid && validated ? false : null"
                  class="tw-text-secondary-text tw-mt-4"
                  data-test="terms"
                  value="true"
                  required
                >
                  <div class="tw-inline tw--mt-1">
                    <i18n
                      path="app.paragraphs.register.tos"
                      tag="span"
                    >
                      <template #tos>
                        <a
                          :href="tos"
                          target="_blank"
                          rel="noopener"
                          v-text="$t('app.paragraphs.register.tos_label')"
                          class="tw-text-blue-500 hover:tw-text-blue-600"
                          data-test="tos"
                        />
                      </template>
                    </i18n>
                  </div>
                </b-form-checkbox>
                <div
                  v-if="invalid && validated"
                  class="tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs"
                  v-text="$t('validator.required')"
                />
              </template>
            </ValidationProvider>

            <ValidationProvider
              ref="pro-provider"
              name="pro"
              rules="required|length:4"
              data-test="pro-provider"
            >
              <template slot-scope="{ invalid, validated }">
                <b-form-checkbox
                  v-model="formData.pro"
                  :disabled="$wait.is('creating account')"
                  :state="invalid && validated ? false : null"
                  class="tw-text-secondary-text tw-mt-4"
                  data-test="pro"
                  value="true"
                  required
                >
                  <div class="tw-inline tw--mt-1">
                    <span
                      v-text="$t('app.paragraphs.register.pro')"
                    />
                  </div>
                </b-form-checkbox>
                <div
                  v-if="invalid && validated"
                  class="tw-text-red-500 tw-pl-6 tw-mt-1 tw-text-xs"
                  v-text="$t('validator.required')"
                />
              </template>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <register-navigation
      :submit-disabled="$wait.is('creating account')"
      @back="$router.go(-1)"
      @submit="submitted"
      data-test="navigation"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'
  import store from '@/store'

  import { showToaster } from '@/services/Toaster'
  import { EventBus } from '@/services/EventBus'
  import Config from '@/services/Config'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import RegisterNavigation from '@/views/Common/RegisterFlow/components/RegisterNavigation/index.vue'
  import RegisterHeader from '@/views/Common/RegisterFlow/components/RegisterHeader/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'

  /**
   * @module view - RegisterAccountCredentials
   */
  export default defineComponent({
    name: 'RegisterAccountCredentials',
    components: {
      RegisterNavigation,
      RegisterHeader,
      CtkInputText
    },
    beforeRouteEnter (to, from, next) {
      const account = store.getters['auth/register/getAccount']
      if (account.firstName && account.lastName && account.phone) {
        return next()
      }

      next({
        name: 'RegisterAccountInfos',
        params: to.params
      })
    },
    data () {
      return {
        tos: `${Config.get('wwwBase')}goto/tos`,
        formData: {
          email: null,
          password: null,
          passwordConfirmation: null,
          tos: false,
          pro: false
        }
      }
    },
    mounted () {
      const account = this.getAccount
      if (account) {
        const { email, password, tos, pro } = account
        this.formData = {
          email,
          password,
          passwordConfirmation: password,
          tos,
          pro
        }
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getQuestions',
        'getAccount'
      ]),
      /**
       * @function steps
       * @returns {string}
       */
      steps () {
        const questions = this.getQuestions.questions.length + 3
        return `${questions}/${questions}`
      }
    },
    methods: {
      ...mapActions('auth/register', [
        'requestRegister',
        'setAccount'
      ]),
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            if (!(this.formData.tos || this.formData.pro)) return

            // @ts-ignore
            const { email, password, tos, pro } = this.formData

            this.setAccount({
              email,
              password,
              tos,
              pro
            })

            this.$wait.start('creating account')
            this.requestRegister(this.$route.params.userType === 'shipper')
              .then(() => {
                this.$router.push({
                  name: 'RegisterConfirmation',
                  params: this.$route.params
                })
                  .catch(() => {})
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  /**
                   * Show the violation message in the input
                   */
                  if (data.error.violations) {
                    const violations = data.error.violations.map((/** @type {import('@/resources/handlers/violations').Violation} */ violation) => violation.property_path)
                    const companyViolations = ['address.street', 'address.city', 'address.postal_code', 'address.country', 'vat_number', 'name']
                    if (companyViolations.some(violation => violations.includes(violation))) {
                      this.$router.push({
                        name: 'RegisterCompany',
                        params: this.$route.params
                      })
                        .then(() => {
                          this.$nextTick(() => {
                            EventBus.$emit('register:company:violations', data.error.violations)
                          })
                        })
                        .catch(() => {})

                      return
                    }

                    const infoViolations = ['phone', 'user.first_name', 'user.last_name']
                    if (infoViolations.some(violation => violations.includes(violation))) {
                      this.$router.push({
                        name: 'RegisterAccountInfos',
                        params: this.$route.params
                      })
                        .then(() => {
                          this.$nextTick(() => {
                            EventBus.$emit('register:infos:violations', data.error.violations)
                          })
                        })
                        .catch(() => {})
                    } else {
                      try {
                        /**
                         * @type {{[key: string]: string}}
                         */
                        const propertyMatch = {
                          'user.email': 'email',
                          'user.password': 'password',
                          'user.terms_of_service': 'tos',
                          'user.professionals': 'pro'
                        }

                        /**
                         * Make a little transformation to match the violation property path
                         * and the front provider names.
                         */
                        handlePropertyPathViolations.call(this, data.error.violations.map((/** @type {import('@/resources/handlers/violations').Violation} */ violation) => {
                          if (Object.keys(propertyMatch).includes(violation.property_path)) {
                            violation.property_path = propertyMatch[violation.property_path]
                          }

                          return violation
                        }))
                      } catch (err) {
                        showToaster(this, err.message, {
                          type: 'error'
                        })
                      }
                    }
                    return
                  }

                  const errorMessage = data.error.detail || data.error.title
                  showToaster(this, errorMessage, {
                    type: 'error'
                  })
                }
              })
              .finally(() => {
                this.$wait.end('creating account')
              })
          })
      }
    }
  })
</script>

<style lang="scss">
.register-account-credentials__wrapper {
  width: 100%;
}
@media (min-width: 770px) {
  .register-account-credentials__wrapper {
    max-width: 300px;
  }
}
.register-account-credentials__wrapper--wide {
  max-width: 460px;
}
.register-account-credentials__content {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 770px) {
  .register-account-credentials__content {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
.register-account-credentials__content__notice {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  background-color: $divider;
}
.register-account-credentials__answers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.register-account-credentials__answers .ui-fat-radio-item {
  min-height: 40px;
}
.register-account-credentials__answers .ui-fat-radio-item__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.register-account-credentials__answers .ui-fat-radio-item.active img {
  filter: brightness(250%);
}
.register-account-credentials__answers__title {
  text-align: left;
}
.register-account-credentials__answers__icon {
  margin-right: 0.75rem;
  max-width: 100%;
  width: 1.5rem;
  max-height: 25px;
}
@media (min-width: 770px) {
  .register-account-credentials__wrapper--wide .ui-fat-radio-item {
    min-height: 80px;
    flex: 1 1 calc(33% - 1rem);
    max-width: calc(33.33% - 0.6rem);
  }
  .register-account-credentials__wrapper--wide .ui-fat-radio-item__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: auto;
    margin-bottom: 0px;
  }
  .register-account-credentials__wrapper--wide .ui-fat-radio-item.has-icon .ui-fat-radio-item__container {
    margin-top: 1.5rem;
  }
  .register-account-credentials__wrapper--wide .register-account-credentials__answers {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .register-account-credentials__wrapper--wide .ui-fat-radio-item:not(:nth-child(3n)) {
    margin-right: 1rem;
  }
  .register-account-credentials__wrapper--wide .register-account-credentials__answers__icon {
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: 40px;
  }
  .register-account-credentials__wrapper--wide .register-account-credentials__answers__title {
    text-align: center;
  }
  .register-account-credentials__wrapper--wide .ui-fat-radio-item.has-icon .register-account-credentials__answers__title {
    min-height: 3rem;
  }
  .register-account-credentials__wrapper:not(.register-account-credentials__wrapper--wide) .ui-fat-radio-item__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}
</style>
