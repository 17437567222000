<template>
  <div class="register-steps tw-flex tw-flex-col tw-bg-secondary-lighten">
    <div class="register-steps__content tw-flex tw-flex-col tw-justify-center lg:tw-w-2/3 tw-my-auto">
      <register-steps-item
        :title="$t('auth.titles.register.company')"
        :description="$t('auth.paragraphs.register.company')"
        :progress="getCompanyProgress"
        :active="isCompanyActive.value"
        class="tw-mb-12"
        data-test="company"
      >
        <template
          slot-scope="{ completed }"
        >
          <img
            :src="completed
              ? require('@/assets/img/icons/register/hotel/completed-animation.svg')
              : isCompanyActive.value
                ? require('@/assets/img/icons/register/hotel/in_progress.svg')
                : require('@/assets/img/icons/register/hotel/unstarted.svg')"
            width="50"
            height="50"
            class="tw-m-auto"
          >
        </template>
      </register-steps-item>
      <register-steps-item
        :title="$t($route.params.userType === 'shipper'
          ? 'auth.titles.register.needs'
          : 'auth.titles.register.needs_carrier')"
        :description="$t($route.params.userType === 'shipper'
          ? 'auth.paragraphs.register.needs'
          : 'auth.paragraphs.register.needs_carrier')"
        :progress="getTransportProgress"
        :active="isTransportActive.value"
        class="tw-mb-12"
        data-test="needs"
      >
        <template
          slot-scope="{ completed }"
        >
          <img
            :src="completed
              ? require('@/assets/img/icons/register/truck/completed-animated.svg')
              : isTransportActive.value
                ? require('@/assets/img/icons/register/truck/in_progress.svg')
                : require('@/assets/img/icons/register/truck/unstarted.svg')"
            width="50"
            height="50"
            class="tw-m-auto"
          >
        </template>
      </register-steps-item>
      <register-steps-item
        :title="$t($route.params.userType === 'shipper'
          ? 'auth.titles.register.about'
          : 'auth.titles.register.about_carrier')"
        :description="$t($route.params.userType === 'shipper'
          ? 'auth.paragraphs.register.about'
          : 'auth.paragraphs.register.about_carrier')"
        :progress="getAccountProgress"
        :active="isAccountActive.value"
        data-test="about"
      >
        <template
          slot-scope="{ completed }"
        >
          <img
            :src="completed
              ? require('@/assets/img/icons/register/head/completed-animated.svg')
              : isAccountActive.value
                ? require('@/assets/img/icons/register/head/in_progress.svg')
                : require('@/assets/img/icons/register/head/unstarted.svg')"
            width="50"
            height="50"
            class="tw-m-auto"
          >
        </template>
      </register-steps-item>
    </div>
  </div>
</template>

<script>
  import { computed, ref } from '@vue/composition-api'
  import { mapGetters } from 'vuex'
  import useRoute from '@/composables/useRoute'
  import useStore from '@/composables/useStore'

  import RegisterStepsItem from './_subs/RegisterStepsItem/index.vue'

  /**
   * @module component - RegisterSteps
   */
  export default {
    name: 'RegisterSteps',
    components: {
      RegisterStepsItem
    },
    setup () {
      /**
       * @function isActive
       * @param {number} section
       * @returns {import('@vue/composition-api').ComputedRef<boolean>}
       */
      function isActive (section) {
        return computed(() => {
          const store = useStore()
          const route = useRoute()
          const { name, params: { index } } = route.value

          if (name === 'RegisterQuestions') {
            const questionByIndex = store.value.getters['auth/register/getQuestions'].questions[index]
            if (questionByIndex) {
              const questions = store.value.getters['auth/register/getCategoriesQuestions'].get(section)
              if (questions && questions.map((/** @type {any} */ v) => v.key).includes(questionByIndex.key)) return true
            }
          }

          return false
        })
      }

      const isCompanyActive = computed(() => {
        const route = useRoute()
        return route.value.name === 'RegisterCompany' ? ref(true) : isActive(1)
      })
      const isTransportActive = computed(() => isActive(2))
      const isAccountActive = computed(() => {
        const route = useRoute()
        return ['RegisterAccountInfos', 'RegisterAccountCredentials'].includes(route.value.name)
          ? ref(true)
          : isActive(3)
      })

      return {
        isCompanyActive,
        isTransportActive,
        isAccountActive
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getCompanyProgress',
        'getTransportProgress',
        'getAccountProgress'
      ])
    }
  }
</script>

<style lang="scss" scoped>
.register-steps {
  padding-top: 67px;
  padding-bottom: 84px;
}
.register-steps .register-steps-item {
  position: relative;
  z-index: 30;
  left: -1rem;
}
.register-steps__content {
  height: 66%;
}
</style>
