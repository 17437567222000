var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-account-infos tw-flex tw-flex-col tw-flex-1"},[_c('div',{staticClass:"register-account-infos__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"},[_c('div',{staticClass:"register-account-infos__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"},[_c('register-header',{staticClass:"tw-mb-10",attrs:{"title":_vm.$t('app.titles.register.infos'),"steps":_vm.steps}}),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"last_name-provider",attrs:{"name":_vm.$t('app.fields.last_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"last_name","label":_vm.$t('app.labels.last_name'),"error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"data-vv-as":_vm.$t('app.fields.last_name'),"type":"text","name":"last_name","autocomplete":"family-name","required":""},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", $$v)},expression:"formData.lastName"}})]}}])}),_c('ValidationProvider',{ref:"first_name-provider",attrs:{"name":_vm.$t('app.fields.first_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"first_name","label":_vm.$t('app.labels.first_name'),"error":invalid && validated || errors && errors.length > 0,"hint":errors[0],"type":"text","name":"first_name","autocomplete":"given-name","required":""},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", $$v)},expression:"formData.firstName"}})]}}])}),_c('ValidationProvider',{ref:"phone-provider",attrs:{"rules":("required|telephone:" + (_vm.formData.country)),"name":_vm.$t('phone'),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var invalid = ref.invalid;
var validated = ref.validated;
return [_c('ctk-phone-number',{attrs:{"id":"phone","country":_vm.formData.country,"hint":errors[0],"error":invalid && validated || errors && errors.length > 0,"name":"phone","autocomplete":"tel-national","required":""},on:{"update:country":function($event){return _vm.$set(_vm.formData, "country", $event)},"update":_vm.phoneUpdated},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}})]}}])})],1)])],1)]),_c('register-navigation',{attrs:{"submit-disabled":false},on:{"back":function($event){return _vm.$router.go(-1)},"submit":_vm.submitted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }