<template>
  <div
    class="register-account-infos tw-flex tw-flex-col tw-flex-1"
  >
    <div class="register-account-infos__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0">
      <div
        class="register-account-infos__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"
      >
        <register-header
          :title="$t('app.titles.register.infos')"
          :steps="steps"
          class="tw-mb-10"
          data-test="header"
        />

        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            @submit.prevent="submitted"
          >
            <ValidationProvider
              ref="last_name-provider"
              :name="$t('app.fields.last_name')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ errors, invalid, validated }">
                <ctk-input-text
                  id="last_name"
                  v-model="formData.lastName"
                  :label="$t('app.labels.last_name')"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  :data-vv-as="$t('app.fields.last_name')"
                  type="text"
                  name="last_name"
                  autocomplete="family-name"
                  class="tw-mb-4"
                  required
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="first_name-provider"
              :name="$t('app.fields.first_name')"
              rules="required|max:255"
              slim
            >
              <template slot-scope="{ errors, invalid, validated }">
                <ctk-input-text
                  id="first_name"
                  v-model="formData.firstName"
                  :label="$t('app.labels.first_name')"
                  :error="invalid && validated || errors && errors.length > 0"
                  :hint="errors[0]"
                  type="text"
                  name="first_name"
                  autocomplete="given-name"
                  class="tw-mb-4"
                  required
                />
              </template>
            </ValidationProvider>
            <ValidationProvider
              ref="phone-provider"
              :rules="`required|telephone:${formData.country}`"
              :name="$t('phone')"
              slim
            >
              <template slot-scope="{ errors, invalid, validated }">
                <ctk-phone-number
                  id="phone"
                  v-model="formData.phone"
                  :country.sync="formData.country"
                  :hint="errors[0]"
                  :error="invalid && validated || errors && errors.length > 0"
                  name="phone"
                  autocomplete="tel-national"
                  required
                  @update="phoneUpdated"
                />
              </template>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <register-navigation
      :submit-disabled="false"
      @back="$router.go(-1)"
      @submit="submitted"
      data-test="navigation"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'
  import store from '@/store'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  import { EventBus } from '@/services/EventBus'
  import { showToaster } from '@/services/Toaster'
  import handlePropertyPathViolations from '@/resources/handlers/violations'
  import RegisterNavigation from '@/views/Common/RegisterFlow/components/RegisterNavigation/index.vue'
  import RegisterHeader from '@/views/Common/RegisterFlow/components/RegisterHeader/index.vue'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'

  /**
   * @module view - RegisterAccountInfos
   */
  export default defineComponent({
    name: 'RegisterAccountInfos',
    components: {
      RegisterNavigation,
      RegisterHeader,
      CtkInputText,
      CtkPhoneNumber
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      const progress = store.getters['auth/register/isTransportCompleted']
      if (progress) {
        return next()
      }

      next({
        name: 'RegisterQuestions',
        params: {
          ...to.params,
          index: 0
        }
      })
    },
    mounted () {
      EventBus.$on('register:infos:violations', this.updateViolations)

      const { firstName, lastName, phone } = this.getAccount
      if (firstName && lastName && phone) {
        const parsedPhone = parsePhoneNumberFromString(phone)

        this.formData = {
          firstName,
          lastName,
          country: parsedPhone && parsedPhone.country ? parsedPhone.country : 'FR',
          phoneFormatted: parsedPhone ? parsedPhone.formatInternational() : null,
          phone: parsedPhone
            ? parsedPhone.formatNational()
            : phone
        }
      }
    },
    data () {
      return {
        /** @type {{
         *  firstName: string|null
         *  lastName: string|null
         *  phone: string|null
         *  phoneFormatted: string|null
         *  country: string|null
         * }} */
        formData: {
          firstName: null,
          lastName: null,
          phone: null,
          phoneFormatted: null,
          country: 'FR'
        }
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getQuestions',
        'getAccount'
      ]),
      /**
       * @function steps
       * @returns {string}
       */
      steps () {
        const questions = this.getQuestions.questions.length + 3
        return `${questions - 1}/${questions}`
      }
    },
    methods: {
      ...mapActions('auth/register', [
        'setAccount'
      ]),
      /**
       * @function updateViolations
       * @param {Array<import('@/resources/handlers/violations').Violation>} violations
       */
      updateViolations (violations) {
        try {
          /**
           * @type {{[key: string]: string}}
           */
          const propertyMatch = {
            'user.first_name': 'first_name',
            'user.last_name': 'last_name'
          }

          /**
           * Make a little transformation to match the violation property path
           * and the front provider names.
           */
          handlePropertyPathViolations.call(this, violations.map(violation => {
            if (Object.keys(propertyMatch).includes(violation.property_path)) {
              violation.property_path = propertyMatch[violation.property_path]
            }

            return violation
          }))
        } catch (err) {
          showToaster(this, err.message, {
            type: 'error'
          })
        }
      },
      phoneUpdated (v) {
        this.formData.country = v.countryCode || 'FR'
        this.formData.phoneFormatted = v.formattedNumber
      },
      submitted () {
        // @ts-ignore
        this.$refs.observer.validate()
          .then((/** @type {boolean} */ valid) => {
            if (!valid) return

            // @ts-ignore
            const { firstName, lastName, phoneFormatted } = this.formData
            this.setAccount({
              firstName,
              lastName,
              phone: phoneFormatted
            })

            this.$router.push({
              name: 'RegisterAccountCredentials',
              params: {
                userType: this.$route.params.userType
              }
            })
              .catch(() => {})
          })
      }
    },
    beforeDestroy () {
      EventBus.$off('register:infos:violations', this.updateViolations)
    }
  })
</script>

<style lang="scss">
.register-account-infos__wrapper {
  width: 100%;
}
@media (min-width: 770px) {
  .register-account-infos__wrapper {
    max-width: 300px;
  }
}
.register-account-infos__wrapper--wide {
  max-width: 460px;
}
.register-account-infos__content {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 770px) {
  .register-account-infos__content {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
.register-account-infos__content__notice {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  background-color: $divider;
}
.register-account-infos__answers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.register-account-infos__answers .ui-fat-radio-item {
  min-height: 40px;
}
.register-account-infos__answers .ui-fat-radio-item__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}
.register-account-infos__answers .ui-fat-radio-item.active img {
  filter: brightness(250%);
}
.register-account-infos__answers__title {
  text-align: left;
}
.register-account-infos__answers__icon {
  margin-right: 0.75rem;
  max-width: 100%;
  width: 1.5rem;
  max-height: 25px;
}
@media (min-width: 770px) {
  .register-account-infos__wrapper--wide .ui-fat-radio-item {
    min-height: 80px;
    flex: 1 1 calc(33% - 1rem);
    max-width: calc(33.33% - 0.6rem);
  }
  .register-account-infos__wrapper--wide .ui-fat-radio-item__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: auto;
    margin-bottom: 0px;
  }
  .register-account-infos__wrapper--wide .ui-fat-radio-item.has-icon .ui-fat-radio-item__container {
    margin-top: 1.5rem;
  }
  .register-account-infos__wrapper--wide .register-account-infos__answers {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .register-account-infos__wrapper--wide .ui-fat-radio-item:not(:nth-child(3n)) {
    margin-right: 1rem;
  }
  .register-account-infos__wrapper--wide .register-account-infos__answers__icon {
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: 40px;
  }
  .register-account-infos__wrapper--wide .register-account-infos__answers__title {
    text-align: center;
  }
  .register-account-infos__wrapper--wide .ui-fat-radio-item.has-icon .register-account-infos__answers__title {
    min-height: 3rem;
  }
  .register-account-infos__wrapper:not(.register-account-infos__wrapper--wide) .ui-fat-radio-item__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}
</style>
