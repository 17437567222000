<template>
  <div
    class="register-questions tw-flex tw-flex-col tw-flex-1"
  >
    <div class="register-questions__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0">
      <div
        :class="{
          'register-questions__wrapper--wide': isWide,
          'register-questions__wrapper--two': question.answers.length === 2
        }"
        class="register-questions__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-4"
        data-test="wrapper"
      >
        <register-header
          :title="question.title"
          :steps="steps"
          :suffix="question.multiple_choice ? $t('app.paragraphs.multiple_answers') : null"
          class="tw-mb-10"
          data-test="header"
        />

        <ui-fat-radio-group
          v-model="formData.answers"
          :items="answers"
          class="register-questions__answers tw-mb-5"
          data-test="types"
        >
          <template slot-scope="{ item, active, keydown }">
            <ui-fat-radio-item
              :key="item.value"
              :title="item.title"
              :active="active"
              :class="{
                'has-icon': !!item.icon
              }"
              class="tw-flex-shrink-0"
              @keydown.native="keydown"
              @click.native="toggle(item.value)"
            >
              <img
                v-if="item.icon"
                :key="item.value"
                :src="item.icon"
                alt=""
                class="register-questions__answers__icon tw-object-contain tw-select-none tw-pointer-events-none"
              >
              <div
                v-text="item.title"
                class="register-questions__answers__title"
              />
            </ui-fat-radio-item>
          </template>
        </ui-fat-radio-group>
      </div>
    </div>
    <register-navigation
      :submit-disabled="!isAnswerValid"
      @back="$router.go(-1)"
      @submit="submitted"
      data-test="navigation"
    />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { readonly } from '@vue/composition-api'
  import store from '@/store'

  import { countries } from '@/components/CtkPhoneNumber/assets/js/phoneCodeCountries.js'
  import RegisterNavigation from '@/views/Common/RegisterFlow/components/RegisterNavigation/index.vue'
  import RegisterHeader from '@/views/Common/RegisterFlow/components/RegisterHeader/index.vue'
  import UiFatRadioGroup from '@/components/UI/FatRadioGroup/index.vue'
  import UiFatRadioItem from '@/components/UI/FatRadioGroup/FatRadioItem/index.vue'

  /**
   * @module view - RegisterCompany
   */
  export default {
    name: 'RegisterCompany',
    components: {
      RegisterNavigation,
      RegisterHeader,
      UiFatRadioGroup,
      UiFatRadioItem
    },
    setup () {
      const countriesList = readonly(countries)

      return {
        countries: countriesList
      }
    },
    beforeRouteUpdate (to, from, next) {
      this.formData.answers = []

      const questions = store.getters['auth/register/getQuestions']
      if (questions && questions.questions && questions.questions[to.params.index]) {
        const question = questions.questions[to.params.index]

        /**
         * Here we want to (re) pre-fill the form with data already set by
         * the user (in the case of a back navigation).
         */
        const answers = store.getters['auth/register/getAnswers']
        if (answers[question.key]) {
          this.formData.answers = [...answers[question.key]]
        }

        next()
      } else {
        next({
          name: 'Register'
        })
      }
    },
    beforeRouteEnter (to, from, next) {
      if (typeof to.params.index === 'undefined') {
        return next({
          name: 'RegisterQuestions',
          params: {
            index: 0
          }
        })
      }

      /**
       * Make sure the user filled the company informations
       */
      const company = store.getters['auth/register/getCompany']
      if (!company) {
        return next({
          name: 'RegisterCompany',
          params: to.params
        })
      }

      /**
       * Validate if the question exists, otherwise redirect to the
       * main register view.
       */
      const questions = store.getters['auth/register/getQuestions']
      if (questions && questions.questions && questions.questions[to.params.index]) {
        next()
      } else {
        next({
          name: 'Register'
        })
      }
    },
    data () {
      return {
        formData: {
          answers: []
        }
      }
    },
    mounted () {
      /**
       * Here we want to (re) pre-fill the form with data already set by
       * the user (in the case of a back navigation).
       */
      const answers = this.getAnswers
      if (answers && answers[this.question.key]) {
        this.formData.answers = [...answers[this.question.key]]
      }
    },
    computed: {
      ...mapGetters('auth/register', [
        'getQuestions',
        'getAnswers'
      ]),
      /**
       * @function isWide
       * @returns {boolean}
       */
      isWide () {
        const withIcons = this.question.answers.some(answer => !!answer.icon)
        return this.question.answers.length <= 6 && withIcons
      },
      /**
       * @function steps
       * @returns {string}
       */
      steps () {
        const { index } = this.$route.params
        // @ts-ignore
        const step = parseInt(index, 10)
        return `${2 + step}/${this.getQuestions.questions.length + 3}`
      },
      /**
       * Returns the current question according to the route.
       * @function question
       * @returns {object}
       */
      question () {
        return this.getQuestions.questions[this.$route.params.index]
      },
      /**
       * @function isAnswerValid
       * @returns {boolean}
       */
      isAnswerValid () {
        const { answers } = this.formData
        if (this.question.multiple_choice) {
          return answers.length > 0
        }

        return answers.length === 1
      },
      /**
       * @function answers
       * @returns {Array<any>}
       */
      answers () {
        return this.question.answers.map(answer => ({
          title: answer.text,
          value: answer.key,
          icon: answer.icon
        }))
      }
    },
    methods: {
      ...mapActions('auth/register', [
        'setCompany',
        'setAnswer'
      ]),
      /**
       * @function toggle
       * @param {string} item
       */
      toggle (item) {
        if (!this.question.multiple_choice) {
          this.formData.answers = [item]
          return
        }

        if (this.formData.answers.includes(item)) {
          const index = this.formData.answers.findIndex(answer => answer === item)
          if (index !== -1) {
            this.formData.answers.splice(index, 1)
          }
        } else {
          this.formData.answers.push(item)
        }
      },
      submitted () {
        if (!this.isAnswerValid) return

        this.setAnswer({
          questionKey: `${this.question.key}`,
          answers: [...this.formData.answers]
        })

        /**
         * Redirect the user to the next question OR to the final step
         * if there are no more questions
         */
        const index = parseInt(this.$route.params.index, 10)
        const nextQuestion = this.getQuestions.questions[index + 1]
        if (nextQuestion) {
          this.$router.push({
            name: 'RegisterQuestions',
            params: {
              index: index + 1
            }
          })
            .catch(() => {})
        } else {
          this.$router.push({
            name: 'RegisterAccountInfos'
          })
            .catch(() => {})
        }
      }
    }
  }
</script>

<style lang="scss">
.register-questions__wrapper {
  width: 100%;
}
@media (min-width: 770px) {
  .register-questions__wrapper {
    max-width: 300px;
  }
}
.register-questions__wrapper--wide:not(.register-questions__wrapper--two) {
  max-width: 460px;
}
.register-questions__content {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 770px) {
  .register-questions__content {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
.register-questions__content__notice {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  background-color: $divider;
}
.register-questions__answers {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.register-questions__answers .ui-fat-radio-item {
  margin-bottom: 1rem;
  min-height: 40px !important;
}
.register-questions__answers .ui-fat-radio-item__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.register-questions__answers .ui-fat-radio-item.active img {
  filter: brightness(250%);
}
.register-questions__answers__title {
  text-align: left;
  line-height: 18px;
}
.register-questions__answers__icon {
  margin-right: 0.75rem;
  max-width: 100%;
  width: 1.5rem;
  max-height: 25px;
}
@media (min-width: 770px) {
  .register-questions__wrapper--wide .ui-fat-radio-item {
    min-height: 80px !important;
    flex: 1 1 calc(33% - 1rem);
    max-width: calc(33.33% - 0.6rem);
  }
  @supports (display: grid) {
    .register-questions__wrapper--wide .ui-fat-radio-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0;
      -webkit-flex: 1 1 0;
      flex: 1 1 0;
      margin-bottom: 0px;
      max-width: 100%;
    }
  }
  .register-questions__wrapper--wide .ui-fat-radio-item__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    margin: auto;
    margin-bottom: 0px;
  }
  .register-questions__wrapper--wide .ui-fat-radio-item.has-icon .ui-fat-radio-item__container {
    margin-top: 1.5rem;
  }
  .register-questions__wrapper--wide .register-questions__answers {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  @supports (display: grid) {
    .register-questions__wrapper--wide .register-questions__answers {
      display: -ms-grid;
      display: grid;
      grid-gap: 1rem;
      gap: 1rem;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  .register-questions__wrapper--wide .ui-fat-radio-item:not(:nth-child(3n)) {
    margin-right: 1rem;
  }
  @supports (display: grid) {
    .register-questions__wrapper--wide .ui-fat-radio-item:not(:nth-child(3n)) {
      margin-right: 0px;
    }
  }
  .register-questions__wrapper--wide.register-questions__wrapper--two .ui-fat-radio-item {
    flex: 1 1 calc(50% - 1rem);
    max-width: calc(50% - 0.6rem);
  }
  @supports (display: grid) {
    .register-questions__wrapper--wide.register-questions__wrapper--two .ui-fat-radio-item {
      -webkit-box-flex: 1;
      -ms-flex: 1 1 0;
      -webkit-flex: 1 1 0;
      flex: 1 1 0;
      max-width: 100%;
    }
    .register-questions__wrapper--wide.register-questions__wrapper--two .register-questions__answers {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .register-questions__wrapper--wide .register-questions__answers__icon {
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    width: auto;
    max-height: 40px;
  }
  .register-questions__wrapper--wide .register-questions__answers__title {
    text-align: center;
  }
  .register-questions__wrapper--wide .ui-fat-radio-item.has-icon .register-questions__answers__title {
    min-height: 3rem;
  }
  .register-questions__wrapper:not(.register-questions__wrapper--wide) .ui-fat-radio-item__container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  @media only screen and (min-height: 860px) {
    .register-questions__wrapper:not(.register-questions__wrapper--wide) .register-questions__answers {
      overflow-y: auto;
      max-height: 300px;
    }
  }
}
</style>
