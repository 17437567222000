var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-questions tw-flex tw-flex-col tw-flex-1"},[_c('div',{staticClass:"register-questions__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"},[_c('div',{staticClass:"register-questions__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-4",class:{
        'register-questions__wrapper--wide': _vm.isWide,
        'register-questions__wrapper--two': _vm.question.answers.length === 2
      }},[_c('register-header',{staticClass:"tw-mb-10",attrs:{"title":_vm.question.title,"steps":_vm.steps,"suffix":_vm.question.multiple_choice ? _vm.$t('app.paragraphs.multiple_answers') : null}}),_c('ui-fat-radio-group',{staticClass:"register-questions__answers tw-mb-5",attrs:{"items":_vm.answers},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
      var active = ref.active;
      var keydown = ref.keydown;
return [_c('ui-fat-radio-item',{key:item.value,staticClass:"tw-flex-shrink-0",class:{
              'has-icon': !!item.icon
            },attrs:{"title":item.title,"active":active},nativeOn:{"keydown":function($event){return keydown.apply(null, arguments)},"click":function($event){return _vm.toggle(item.value)}}},[(item.icon)?_c('img',{key:item.value,staticClass:"register-questions__answers__icon tw-object-contain tw-select-none tw-pointer-events-none",attrs:{"src":item.icon,"alt":""}}):_vm._e(),_c('div',{staticClass:"register-questions__answers__title",domProps:{"textContent":_vm._s(item.title)}})])]}}]),model:{value:(_vm.formData.answers),callback:function ($$v) {_vm.$set(_vm.formData, "answers", $$v)},expression:"formData.answers"}})],1)]),_c('register-navigation',{attrs:{"submit-disabled":!_vm.isAnswerValid},on:{"back":function($event){return _vm.$router.go(-1)},"submit":_vm.submitted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }