<template>
  <div
    :class="{
      'register-steps-item--active': active
    }"
    class="register-steps-item tw-flex tw-items-center"
  >
    <register-steps-item-progress
      :value="progress"
      class="tw-mr-4"
      data-test="progress"
    >
      <slot
        v-bind="{
          completed: progress === 100
        }"
      />
    </register-steps-item-progress>
    <div class="register-steps-item__content tw-text-white">
      <div
        v-text="title"
        class="register-steps-item__content__title tw-text-xl"
        data-test="title"
      />
      <p
        v-text="description"
        class="tw-text-sm tw-mb-0"
        data-test="description"
      />
    </div>
  </div>
</template>

<script>
  import RegisterStepsItemProgress from './_subs/RegisterStepsItemProgress/index.vue'

  /**
   * @module component - RegisterStepsItem
   * @param {string} title
   * @param {string} description
   * @param {boolean} [active=true]
   * @param {number} [progress=0] - Up to 100
   */
  export default {
    components: {
      RegisterStepsItemProgress
    },
    props: {
      title: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      active: {
        type: Boolean,
        default: true
      },
      progress: {
        type: Number,
        default: 0
      }
    }
  }
</script>

<style lang="scss" scoped>
.register-steps-item:not(.register-steps-item--active) .register-steps-item__content {
  opacity: 0.75;
}
.register-steps-item--active .register-steps-item__content__title {
  font-weight: 700;
}
</style>
