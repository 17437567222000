var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-company tw-flex tw-flex-col tw-flex-1"},[_c('div',{staticClass:"register-company__content tw-px-4 md:tw-px-0 tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto tw-mb-20 md:tw-mb-0"},[_c('div',{staticClass:"register-company__wrapper tw-mx-auto tw-py-4 md:tw-pt-24 md:tw-pb-12"},[_c('register-header',{staticClass:"tw-mb-10",attrs:{"title":_vm.$t('app.titles.register.company'),"steps":_vm.steps}}),_c('ValidationObserver',{ref:"vat-observer",attrs:{"slim":""}},[_c('form',{attrs:{"disabled":_vm.$wait.is('fetching VAT number') || _vm.editCompany},on:{"submit":function($event){$event.preventDefault();return _vm.retrieveVAT.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"vat_number-provider",staticClass:"register-company__search tw-flex tw-items-center tw-mb-5",attrs:{"name":_vm.$t('app.fields.vat_number'),"rules":'required|max:255',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"register-company__search__field tw-flex-1",attrs:{"id":"vatNumber","error":invalid && validated || errors && errors.length > 0,"label":_vm.$t('app.labels.vat_number'),"hint":errors[0],"loader":_vm.$wait.is('fetching VAT number'),"disabled":_vm.$wait.is('fetching VAT number') || _vm.editCompany,"name":"vat_number","type":"text","required":""},on:{"input":function () { return _vm.company = null; }},model:{value:(_vm.formData.vatNumber),callback:function ($$v) {_vm.$set(_vm.formData, "vatNumber", $$v)},expression:"formData.vatNumber"}}),_c('UiButton',{staticClass:"register-company__search__button !tw-rounded-tl-none !tw-rounded-bl-none",attrs:{"loading":_vm.$wait.is('fetching VAT number'),"disabled":_vm.$wait.is('fetching VAT number') || _vm.editCompany,"type":"submit","variant":"primary"},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-ctk-icon',{attrs:{"name":"search"}})]},proxy:true}],null,true)})]}}])}),(!_vm.editCompany && !_vm.company)?_c('div',{staticClass:"register-company__content__notice tw-rounded tw-flex tw-p-3 tw-mt-4 tw-mb-4"},[_c('p',{staticClass:"tw-flex-1 tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t(_vm.$route.params.userType == 'carrier'
                ? 'app.paragraphs.vat.carrier'
                : 'app.paragraphs.vat.shipper'))}})]):_vm._e(),(_vm.editCompany && _vm.searched && !_vm.company)?_c('div',{staticClass:"register-company__content__notice tw-rounded tw-flex tw-p-2 tw-mt-4 tw-mb-4"},[_c('ui-ctk-icon',{staticClass:"tw-text-2xl tw-mr-1",attrs:{"name":"warning"}}),_c('p',{staticClass:"tw-flex-1 tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.no_vat_found'))}})],1):_vm._e()],1)]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var invalid = ref.invalid;
return [(_vm.editCompany)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationProvider',{ref:"name-provider",attrs:{"name":_vm.$t('app.fields.company_name'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var fieldInvalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"name","name":"name","type":"text","label":_vm.$t('app.labels.company_name'),"error":fieldInvalid && validated || errors && errors.length > 0,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"required":"","autocomplete":"organization"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{ref:"street-provider",attrs:{"name":_vm.$t('app.fields.street_name'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var fieldInvalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"address","name":"address","type":"text","label":_vm.$t('app.labels.street_name'),"error":fieldInvalid && validated || errors && errors.length > 0,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"required":"","autocomplete":"address-line1"},model:{value:(_vm.formData.streetName),callback:function ($$v) {_vm.$set(_vm.formData, "streetName", $$v)},expression:"formData.streetName"}})]}}],null,true)}),_c('div',{staticClass:"tw-flex"},[_c('ValidationProvider',{ref:"postal_code-provider",staticClass:"tw-w-1/2 tw-mr-4",attrs:{"name":_vm.$t('app.fields.postal_code'),"rules":'required|max:10',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var fieldInvalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"postalCode","name":"postal-code","type":"text","label":_vm.$t('app.labels.postal_code'),"error":fieldInvalid && validated || errors && errors.length > 0,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"required":"","autocomplete":"postal-code"},model:{value:(_vm.formData.postalCode),callback:function ($$v) {_vm.$set(_vm.formData, "postalCode", $$v)},expression:"formData.postalCode"}})]}}],null,true)}),_c('ValidationProvider',{ref:"city-provider",staticClass:"tw-w-1/2",attrs:{"name":_vm.$t('app.fields.city'),"rules":'required',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var fieldInvalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-4",attrs:{"id":"city","name":"city","type":"text","label":_vm.$t('app.labels.city'),"error":fieldInvalid && validated || errors && errors.length > 0,"hint":errors[0],"loader":_vm.$wait.is('accepting quotation'),"disabled":_vm.$wait.is('accepting quotation'),"required":"","autocomplete":"address-level2"},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", $$v)},expression:"formData.city"}})]}}],null,true)})],1),_c('ValidationProvider',{ref:"country-provider",attrs:{"name":_vm.$t('app.fields.country'),"rules":"required|max:2","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var fieldInvalid = ref.invalid;
                var validated = ref.validated;
                var errors = ref.errors;
return [_c('country-selector',{staticClass:"tw-mb-4",attrs:{"id":"country","label":_vm.$t('app.labels.country'),"error":!_vm.$wait.is('fetching company') && fieldInvalid && validated || errors && errors.length > 0,"hint":errors[0],"items":_vm.countries,"data-vv-as":_vm.$t('app.fields.country'),"loader":_vm.$wait.is('fetching company'),"preferred-countries":['FR', 'BE', 'DE', 'NL', 'ES', 'IT', 'PL', 'LU', 'MC', 'AD'],"disabled":true,"color":"#287696","has-name":"","type":"text","name":"country","required":"","autocomplete":"country"},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}})]}}],null,true)}),(invalid && _vm.company && !_vm.company.address.isComplete)?_c('div',{staticClass:"register-company__content__notice tw-rounded tw-flex tw-p-2 tw-mt-4 tw-mb-4"},[_c('ui-ctk-icon',{staticClass:"tw-text-2xl tw-mr-1",attrs:{"name":"warning"}}),_c('p',{staticClass:"tw-flex-1 tw-mb-0",domProps:{"textContent":_vm._s(_vm.$t('app.paragraphs.missing_vat_informations'))}})],1):_vm._e()],1):_vm._e()]}}])}),(_vm.company && !_vm.editCompany && _vm.company.address.isComplete)?_c('company-card',{attrs:{"name":_vm.company.name,"address":_vm.company.address},on:{"edit":_vm.edit}}):_vm._e()],1)]),_c('register-navigation',{attrs:{"submit-disabled":!_vm.editCompany && !_vm.company},on:{"back":_vm.back,"submit":_vm.submitted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }